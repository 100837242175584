<template>
    <section>
        <vue-snotify></vue-snotify>
        <b-modal id="dashboard-spot-detail" ref="dashboard-spot-detail" scrollable :title="$t('spot-details')" size="md" :hide-footer="true">
            <div>
                <button @click="backToCluster()" v-if="showBackButton" type="button" class="btn btn-inverse-dark btn-rounded btn-icon"><i class="fa fa-arrow-left"></i></button>
                <div v-if="spotDetailImages.length > 0">
                    <b-carousel
                        id="carousel1"
                        controls
                        indicators
                        background="#ababab"
                        :interval="2000"
                    >
                        <b-carousel-slide v-for="imagess of spotDetailImages" :key="imagess.id">
                            <!-- <img slot="img" :src="serverUrl+images" alt="slide image"/> -->
                            <!-- <img slot="img" v-if="checkImage(imagess)" :src="serverUrl+imagess" alt="slide image"/> -->
                            <expandable-image v-if="checkImage(imagess)" :src="serverUrl+imagess"></expandable-image>
                            <!-- <enlargeable-image v-if="checkImage(imagess)" :src="serverUrl+imagess" :src_large="serverUrl+imagess" trigger="click" /> -->
                            <audio class="mb-5 mt-5  text-justify" controls v-else :src="serverUrl+imagess" ref="audio" @play="playAudio(imagess)"></audio>

                        </b-carousel-slide>
                    </b-carousel>
                </div>
            </div>
            <table class="table table-responsive new-table-responsive  mt-4">
                <tr>
                    <td width="50%"><b>{{ $t('spot-id')}}</b></td>
                    <td width="50%"><span v-if="spotDetail.spot_id">{{spotDetail.spot_id}}</span></td>
                </tr>
                <tr v-if="version_id == 2 && is_pro_sync == 1">
                    <td width="50%"><b>{{ $t('category')}}</b></td>
                    <td width="50%"><span v-if="spotDetail.single_question">{{spotDetail.single_question.category.name}}</span></td>
                </tr>
                <tr>
                    <td width="50%"><b>{{ $t('latitude')}}</b></td>
                    <td width="50%"><span v-if="spotDetail.latitude">{{spotDetail.latitude}}</span></td>
                </tr>
                <tr>
                    <td width="50%"><b>{{ $t('longitude')}}</b></td>
                    <td width="50%"><span v-if="spotDetail.longitude">{{spotDetail.longitude}}</span></td>
                </tr>
                <tr>
                    <td width="50%"><b>{{ $t('spot')}}</b></td>
                    <td width="50%"><span v-if="spotDetail.spot_type">{{spotDetail.spot_type.name}}</span></td>
                </tr>
                <tr>
                    <td width="50%"><b>{{ $t('location')}}</b></td>
                    <td width="50%"><span v-if="spotDetail.location">{{spotDetail.location.name}}</span></td>
                </tr>
                <tr>
                    <td width="50%"><b>{{ $t('date')}}</b></td>
                    <td width="50%"><span v-if="spotDetail.spot_date">{{this.formatDate(spotDetail.spot_date)}}</span>
                    </td>
                </tr>
                <tr>
                    <td width="50%"><b>{{ $t('spotted-by')}}</b></td>
                    <td width="50%"><span v-if="spotDetail.spotter_user">{{spotDetail.spotter_user.user_name}}</span>
                    </td>
                </tr>
                <tr>
                    <td width="50%"><b>{{ $t('detail')}}</b></td>
                    <td width="50%"><span v-if="spotDetail.detail">{{spotDetail.detail}}</span></td>
                </tr>
                <tr v-if="version_id == 2 && is_pro_sync == 1 && spotDetail.desktop_species && spotDetail.desktop_species !== 'NULL'">
                    <td width="50%"><b>{{ $t('Species')}}</b></td>
                    <td width="50%"><span v-if="spotDetail.desktop_species">{{spotDetail.desktop_species}}</span></td>
                </tr>
                <tr v-if="version_id == 2 && is_pro_sync == 1 && spotDetail.desktop_behavior && spotDetail.desktop_behavior !== 'NULL'">
                    <td width="50%"><b>{{ $t('Behavior')}}</b></td>
                    <td width="50%"><span v-if="spotDetail.desktop_behavior">{{spotDetail.desktop_behavior}}</span></td>
                </tr>
                    </table>
                    <hr>
            <vue-tabs active-tab-color="#9c27b0" active-text-color="white" type="pills">
                <v-tab :title="$t('data-collection-questions')" class="edit-tab-body mb-3">
                    <table class="table table-responsive new-table-responsive  mt-4" v-if="spotDetail.spot_questions && spotDetail.spot_questions.length > 0">
                       
                        <div v-for="spot_question of spotDetail.spot_questions" :key="spot_question.id">
                            <tr>
                                <td width="50%"><b><span
                                        v-if="spot_question.question">{{spot_question.question.question}}</span></b></td>
                                <td width="50%"><span v-if="spot_question.answer">{{spot_question.answer}}</span></td>
                            </tr>
                            <div v-if="spot_question.spot_sub_questions && (spot_question.answer !== 'No' || spot_question.answer === null || spot_question.answer === '')">
                                <tr v-for="spot_sub_question of spot_question.spot_sub_questions" :key="spot_sub_question.id">
                                    <td width="50%"><b><span
                                            v-if="spot_sub_question.sub_question">{{spot_sub_question.sub_question.question}}</span></b>
                                    </td>
                                    <td width="50%"><span v-if="spot_sub_question.answer">{{spot_sub_question.answer}}</span>
                                    </td>
                                </tr>
                            </div>
                        </div>
                    </table>
                    <div class="text-center mt-4" v-else>
                        {{  $t('spot-question-details-not-found') }}
                    </div> 
                    </v-tab>
                    <v-tab :title="$t('post-process-data')" v-if="spotDetail.spot_ppd_questions" class="edit-tab-body mb-3">
                        <table class="table table-responsive new-table-responsive  mt-4" v-if="spotDetail.spot_ppd_questions && spotDetail.spot_ppd_questions.length > 0">
                        <div v-if="spotDetail.spot_ppd_questions">
                            <div v-for="spot_ppd_question of spotDetail.spot_ppd_questions" :key="spot_ppd_question.id">
                                <tr>
                                    <td width="50%"><b><span v-if="spot_ppd_question.question">{{spot_ppd_question.question.question}}</span></b></td>
                                    <td width="50%"><span v-if="spot_ppd_question.answer">{{spot_ppd_question.answer}}</span></td>
                                </tr>
                                <div v-if="spot_ppd_question.spot_sub_questions">
                                    <tr v-for="spot_sub_question of spot_ppd_question.spot_sub_questions" :key="spot_sub_question.id">
                                        <td width="50%"><b><span v-if="spot_sub_question.sub_question">{{spot_sub_question.sub_question.question}}</span></b></td>
                                        <td width="50%"><span v-if="spot_sub_question.answer">{{spot_sub_question.answer}}</span></td>
                                    </tr>
                                </div>
                            </div>
                        </div>
                        </table>
                        <div class="text-center mt-4" v-else>
                            {{  $t('spot-question-details-not-found') }}
                        </div> 
                    </v-tab>
                    <v-tab :title="$t('weather')" class="edit-tab-body mb-3" v-if="this.version_id === 2 || this.version_id === 3">
                    <table class="table table-responsive new-table-responsive  mt-4" >
                        <h4>{{ $t('global-weather') }}</h4>
                        <div v-if="spotDetail.spot_local_weather">
                            <div class="mr-4 mt-4">
                                <tr>
                                    <td width="70%"><i class="fas fa-sun mr-2"></i><b><span>{{ $t('state')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_local_weather.state ? spotDetail.spot_local_weather.state: '--' }}</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><i class="fas fa-thermometer-half mr-2"></i><b><span>{{ $t('temperature')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_local_weather.temp_c ? spotDetail.spot_local_weather.temp_c: '0' }} °C</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><i class="fas fa-tint mr-2"></i><b><span>{{ $t('humidity')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_local_weather.humidity ? spotDetail.spot_local_weather.humidity: '0' }} %</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><i class="fas fa-thermometer-half mr-2"></i><b><span>{{ $t('feels-like')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_local_weather.feelslike_c ? spotDetail.spot_local_weather.feelslike_c: '0' }} °C</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><i class="fas fa-tachometer-alt mr-2"></i><b><span>{{ $t('pressure')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_local_weather.pressure_in ? spotDetail.spot_local_weather.pressure_in: '0' }} in</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><i class="fas fa-umbrella mr-2"></i><b><span>{{ $t('precipitation')}} </span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_local_weather.precip_mm ? spotDetail.spot_local_weather.precip_mm: '0' }} mm</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><i class="fas fa-eye mr-2"></i><b><span>{{ $t('visibility')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_local_weather.vis_miles ? spotDetail.spot_local_weather.vis_miles: '0' }} mi</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><b><i class="fas fa-location-arrow mr-2"></i><span>{{ $t('Wind Direction')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_local_weather.wind_degree ? spotDetail.spot_local_weather.wind_degree: '0' }} °</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><i class="fas fa-cloud-sun mr-2"></i><b><span>{{ $t('Cloud Cover')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_local_weather.cloud ?spotDetail.spot_local_weather.cloud: '0' }} %</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><i class="fas fa-wind mr-2"></i><b><span>{{ $t('wind-speed')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_local_weather.wind_mph ? spotDetail.spot_local_weather.wind_mph: '0' }} mph</span></td>
                                </tr>
                            </div>
                        </div>
                        <div class="mr-4 mt-4" v-else>
                            <p class="mb-0" style="text-align: center;">{{  $t('no-data-found-for-given-latitude-and-longitude') }}</p>
                        </div>
                        <hr>
                        <h4 class="mt-4">{{ $t('marine-weather') }}</h4>
                        <div v-if="spotDetail.spot_marine_weather">    
                            <div class="mr-4 mt-4">
                                <tr>
                                    <td width="70%"><i class="fas fa-thermometer-half mr-2"></i> <b><span>{{ $t('water-temperature')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_marine_weather.water_temp_c ? spotDetail.spot_marine_weather.water_temp_c: '0' }} °C</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><i class="fas fa-clock mr-2"></i><b><span>{{ $t('swell-period')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_marine_weather.swell_period_secs ? spotDetail.spot_marine_weather.swell_period_secs: '0' }} sec</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><i class="fas fa-wave-square mr-2"></i><b><span>{{ $t('significant-wave-height')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_marine_weather.sig_ht_mt ? spotDetail.spot_marine_weather.sig_ht_mt: '0' }} m</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><i class="fas fa-water mr-2"></i><b><span>{{ $t('swell-wave-height')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_marine_weather.swell_ht_mt ? spotDetail.spot_marine_weather.swell_ht_mt: '0' }} m</span></td>
                                </tr>
                                <tr>
                                    <td width="70%"><i class="fas fa-compass mr-2"></i><b><span>{{ $t('Swell Direction')}}</span></b></td>
                                    <td width="30%"><span>{{ spotDetail.spot_marine_weather.swell_dir ? spotDetail.spot_marine_weather.swell_dir: '0' }} °</span></td>
                                </tr>       
                            </div>
                        </div>
                        <div class="mr-4 mt-4" v-else>
                            <p class="mb-0" style="text-align: center;">{{  $t('no-data-found-for-given-latitude-and-longitude') }}</p>
                        </div>
                    </table>
                    <hr>
                </v-tab>
                <v-tab v-if="version_id == 2 && is_pro_sync == 1" :title="$t('Environment')" class="edit-tab-body mb-3">
                    <table class="table table-responsive new-table-responsive mt-4" v-if="spotDetail.desktop_weather_data && spotDetail.desktop_weather_data.length > 0">
                        <div v-for="desktop_weather_questions of spotDetail.desktop_weather_data" :key="desktop_weather_questions.id">
                        <tr v-if="desktop_weather_questions.field_name && desktop_weather_questions.field_value">
                            <td width="50%">
                            <b><span v-if="desktop_weather_questions.field_name">{{ $t(formatFieldName(desktop_weather_questions.field_name)) }}</span></b>
                            </td>
                            <td width="50%">
                            <span v-if="desktop_weather_questions.field_value">
                                {{ desktop_weather_questions.field_value }} 
                                <!-- Display the unit if it exists -->
                                <span v-if="getUnit(desktop_weather_questions)">
                                {{ getUnit(desktop_weather_questions) }}
                                </span>
                            </span>
                            </td>
                        </tr>
                        </div>
                    </table>
                    <div class="text-center mt-4" v-else>
                        {{ $t('no-data-found-for-environment') }}
                    </div> 
                </v-tab>
                <v-tab v-if="version_id == 2 && is_pro_sync == 1" :title="$t('Effort')" class="edit-tab-body mb-3">
                    <table class="table table-responsive new-table-responsive  mt-4" v-if="spotDetail.desktop_effort_data && spotDetail.desktop_effort_data.length > 0">
                        <div v-for="desktop_effort_questions of spotDetail.desktop_effort_data" :key="desktop_effort_questions.id">
                            <tr  v-if="desktop_effort_questions.field_name && desktop_effort_questions.field_value">
                                <td width="50%"><b><span
                                        v-if="desktop_effort_questions.field_name">{{ $t(formatFieldName(desktop_effort_questions.field_name)) }}</span></b></td>
                                <td width="50%"><span v-if="desktop_effort_questions.field_value">{{desktop_effort_questions.field_value}}</span></td>
                            </tr>
                        </div>
                    </table>
                    <div class="text-center mt-4" v-else>
                        {{  $t('no-data-found-for-effort') }}
                    </div> 
                </v-tab>
                <v-tab v-if="version_id == 2 && is_pro_sync == 1" :title="$t('GPS Data')" class="edit-tab-body mb-3">
                    <table class="table table-responsive new-table-responsive  mt-4" v-if="spotDetail.gps_heading || spotDetail.gps_speed || spotDetail.gps_altitude || spotDetail.survey_altitude">
                        <tr v-if="version_id == 2 && is_pro_sync == 1 && spotDetail.gps_heading">
                            <td width="50%"><b>{{ $t('Heading')}}</b></td>
                            <td width="50%"><span v-if="spotDetail.gps_heading">{{spotDetail.gps_heading}}</span></td>
                        </tr>
                        <tr v-if="version_id == 2 && is_pro_sync == 1 && spotDetail.gps_speed">
                            <td width="50%"><b>{{ $t('Speed')}}</b></td>
                            <td width="50%"><span v-if="spotDetail.gps_speed">{{spotDetail.gps_speed}}</span></td>
                        </tr>
                        <tr v-if="version_id == 2 && is_pro_sync == 1 && spotDetail.gps_altitude">
                            <td width="50%"><b>{{ $t('Altitude')}}</b></td>
                            <td width="50%"><span v-if="spotDetail.gps_altitude">{{spotDetail.gps_altitude}}</span></td>
                        </tr>
                        <tr v-if="version_id == 2 && is_pro_sync == 1 && spotDetail.survey_altitude">
                            <td width="50%"><b>{{ $t('Survey Altitude')}}</b></td>
                            <td width="50%"><span v-if="spotDetail.survey_altitude">{{spotDetail.survey_altitude}}</span></td>
                        </tr>
                    </table>
                    <div class="text-center mt-4" v-else>
                        {{  $t('No data found for GPS') }}
                    </div> 
                </v-tab>
            </vue-tabs>
        </b-modal>
    </section>
</template>
<script>
    import Vue from "vue";
    import mapboxgl from "mapbox-gl";
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import API from '@/api';
    import API_CIT from '@/citizen_api';
    import moment from 'moment';
    import EnlargeableImage from '@diracleo/vue-enlargeable-image';
    import VueExpandableImage from 'vue-expandable-image'
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(VueExpandableImage);
    Vue.component("EnlargeableImage", EnlargeableImage);
    Vue.use(Snotify, options)
    export default{
        props: ['showRecentSpot'],
        data() {
            return {
                version_id: null,
                is_pro_sync: null,
                PPDQuestion: false,
                spotForm: {
                    requireLatLong: 1,
                    spot_type_id: '',
                    location_id: '',
                    detail: '',
                    spot_date: '',
                    latitude: '',
                    longitude: '',
                    questions: [],
                    client_url: window.location.href.split('#')[0],
                    client_id: JSON.parse(localStorage.getItem("client_id")),
                },
                spotDetailImages: [],
                spotDetail: [],
                spotsData: [],
                showBackButton:false,
            };
        },
        components: {
        },
        created() {
            this.$EventBus.$on("showClusterSpots", (data) => {
                this.$root.$emit("bv::show::modal", "modal-clusters");
            });
        },
        mounted() {
        },
        methods:{
            playAudio(imagess) {
                this.$refs.audio.forEach((audio) => {
                if (audio.src !== this.serverUrl + imagess) {
                    audio.pause();
                }
                });
            },
            getUnit(weatherQuestion) {
                console.log('weatherQuestion', weatherQuestion);

                if (!weatherQuestion || !weatherQuestion.field_name) {
                    return '';
                }

                if (weatherQuestion.field_name === 'wind_speed' && weatherQuestion.wind_speed_unit) {
                    return weatherQuestion.wind_speed_unit
                        .split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ');
                } else if (weatherQuestion.field_name === 'swell_height' && weatherQuestion.swell_height_unit) {
                    return weatherQuestion.swell_height_unit
                        .split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ');
                } else if (weatherQuestion.field_name === 'visibility' && weatherQuestion.visibility_unit) {
                    return weatherQuestion.visibility_unit
                        .split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ');
                }
                return '';
            },
            formatDate(date) {
                return moment(String(date)).format('MM-DD-YYYY HH:mm')
            },
            openSpotterSpotDetail(id,event) {
                this.showBackButton = event;
                const data = {
                    id: id,
                    client_url: window.location.href.split('#')[0],
                }
                this.serverUrl = this.$server_url;
                    API_CIT.getSpotDetail(
                    data,
                    data => {
                        this.spotDetail = data.data;
                        this.version_id = data.version_id;
                        this.is_pro_sync = data.is_pro_sync;
                        this.spotDetailImages = [];
                        if (this.spotDetail.image !== null) {
                            this.spotDetailImages.push(this.spotDetail.image);
                        }

                        if (this.spotDetail.image1 !== null) {
                            this.spotDetailImages.push(this.spotDetail.image1);
                        }

                        if (this.spotDetail.image2 !== null) {
                            this.spotDetailImages.push(this.spotDetail.image2);
                        }
                        this.$root.$emit("bv::hide::modal", "modal-clusters");
                        this.$root.$emit("bv::hide::modal", "recent-spots");
                        this.$refs['dashboard-spot-detail'].show();
                    },
                    err => {
                    });                
            },
            openAdminSpotDetail(id,event) {
                this.showBackButton = event;
                const data = {
                    id: id,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                this.serverUrl = this.$server_url;
                    API.getSpotDetail(
                    data,
                    data => {
                        this.spotDetail = data.data;
                        this.version_id = data.version_id;
                        this.is_pro_sync = data.is_pro_sync;
                        this.spotDetailImages = [];
                        if (this.spotDetail.image !== null) {
                            this.spotDetailImages.push(this.spotDetail.image);
                        }

                        if (this.spotDetail.image1 !== null) {
                            this.spotDetailImages.push(this.spotDetail.image1);
                        }

                        if (this.spotDetail.image2 !== null) {
                            this.spotDetailImages.push(this.spotDetail.image2);
                        }
                        this.$root.$emit("bv::hide::modal", "modal-clusters");
                        this.$root.$emit("bv::hide::modal", "recent-spots");
                        this.$refs['dashboard-spot-detail'].show();
                    },
                    err => {
                    });                
            },
            openReportSpotDetail(id,event) {
                this.showBackButton = event;
                this.serverUrl = this.$server_url;
                let data = {
                    id: id,
                    // client_id: JSON.parse(localStorage.getItem("client_id")),
                };
                API.getReportSpot(
                    data,
                    data => {
                        console.log('fvndb')
                        this.spotDetail = data.data;
                        this.version_id = data.version_id;
                        this.is_pro_sync = data.is_pro_sync;
                        console.log('this.version_id :', this.version_id);
                        this.spotDetailImages = [];
                        if (this.spotDetail.image !== null) {
                            this.spotDetailImages.push(this.spotDetail.image);
                        }
    
                        if (this.spotDetail.image1 !== null) {
                            this.spotDetailImages.push(this.spotDetail.image1);
                        }
    
                        if (this.spotDetail.image2 !== null) {
                            this.spotDetailImages.push(this.spotDetail.image2);
                        }
                        this.$refs['dashboard-spot-detail'].show();
                        // this.getReportsData();
                        // this.$snotify.success('Spot deleted successfully.')
                    },
                    err => {}
                );        
            },
            backToCluster() {
                if(this.showRecentSpot == true){
                console.log('this.showRecentSpot :', this.showRecentSpot);
                    this.$root.$emit("bv::show::modal", "recent-spots");
                }
                else{
                    this.$root.$emit("bv::show::modal", "modal-clusters");
                }
                this.$refs['dashboard-spot-detail'].hide();
            },
            checkImage(image) {
                var found = image.search(/(\.mp3|\.aac)$/i);
                if (found === -1) {
                    return true;
                } else {
                    return false;
                }
            },
            formatFieldName(fieldName) {
                return fieldName
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
            }
        }
    };
</script>
<style scoped lang="scss">
    .basemap {
        width: 100%;
        height: 100%;
    }

    .mapboxgl-canvas {
        width: 1200px;
        height: 689.15px;
        position: static !important;
    }

    .mapboxgl-canvas-container {
        position: static !important;
        width: 1200px !important;
    }

    #menu {
        display: flex;
        position: absolute;
        background: #efefef;
        padding: 10px;
        font-family: 'Open Sans', sans-serif;
        z-index: 1;
        align-items: center;
    }

    #menu label {
        margin-bottom: 0px;
    }
    .google-map-design {
        min-height: calc(100vh - 184px);
        height: 100%;
    }
    .google-map-design #mapContainer {
        height: calc(100vh - 285px);
    }

    /* .google-map-design #mapContainer {
        min-height: calc(100vh - 254px);
        height: 100%;
    } */
    .new-table-responsive  tr td span {
        white-space: pre-wrap;
        line-height: 1.2;
    }
    @media (max-width: 767px) {
        .map-btns button:nth-child(1) {
            margin-right: 0px !important;
        }
    }
    @media (max-width: 705px) {
        #menu {
            width: 80%;
            flex-wrap: wrap;
        }
        .map-btns button:nth-child(1) {
            margin-left: 0px !important;
        }
    }
    @media (max-width: 500px) {
        #menu {
            width: 73%;
            flex-wrap: wrap;
        }
    }
    @media (max-width: 450px) {
        .m-ml {
            margin-left: 0px !important;
        }
    }
    @media (max-width: 375px) {
        .map-label {
            font-size: 13px;
        }
        .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
    @media (max-width: 341px) {
        .map-btns button:nth-child(2) {
            margin-top: 5px;
            margin-left: 0px !important;
        }
        .map-btns button:nth-child(1) {
            margin-right: 5px !important;
        }
    }
    .b-sidebar.b-sidebar-right {
        margin-top: 105px !important;
    }
    .sidebar-counter #sidebar-no-header {
        margin-top: 105px !important;
    }
    .new-ml {
        margin-left: 10px !important;
        color: #fff !important;
    }
    .variant-orange {
        color: #fff !important;
        background-color: #fa6400;
        border-color: #fa6400;
    }

</style>
